<template>
    <v-container fluid>
        <router-link to="/">
            <i class="fas fa-caret-left text--white"></i> ย้อนกลับ
          </router-link>
        <v-row justify='space-between ' class="pt-3">
          <v-col>
              <h1>Microsoft Teams</h1>
          </v-col>
          <v-col class="text-right" >
              <v-btn color="#745CA8" class="white--text vertical-center">
                Activate all Teams
              </v-btn>
          </v-col>
        </v-row>
        <v-row class="pb-5">
          <v-col>
            <h3>วิชา Seminar in Home and Community</h3>
          </v-col>
        </v-row>
        <v-card>
            <v-card-text>
            <v-row>
                <v-col>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>
                                006470 Seminar in Home and Community Sec.001,002[1/64]
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-btn class="mr-1">
                                activate
                            </v-btn>
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>
                                006470 Seminar in Home and Community Sec.001,002[1/64]
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-btn class="mr-1">
                                activate
                            </v-btn>
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>
                                006470 Seminar in Home and Community Sec.001,002[1/64]
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-btn class="mr-1">
                                activate
                            </v-btn>
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <v-divider></v-divider>
                </v-col>
            </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>

  export default {
    name: 'MsTeamsActivate',
    components: {
    },
    created () {

    }
  }
</script>
